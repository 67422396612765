/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
var Parser = require("html-react-parser");

import { deliveryId, pickupId, dineinId } from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPriceValue,
  timeToConv12,
  showPriceValueNew,
  getSurchargesplitup,
  hideLoader,
} from "../Helpers/SettingHelper";

import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "moment";
import { setMinutes, setHours, getDay, format } from "date-fns";
var qs = require("qs");
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_ACTIVITYCOUNT,
  GET_RESERVATIONHISTORY,
  GET_STATIC_BLOCK,
} from "../../actions";

import cookie from "react-cookies";

import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import productImg from "../../common/images/noimg-400x400.jpg";
import innerbanner from "../../common/images/inner-banner.jpg";
var Parser = require("html-react-parser");

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corderdetail: [],
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      deliveryTakeaway_orders: 0,
      homeorderInfo: "",
      homesubscribe: "",
      reservation_orders: 0,
      staticblacks: [],
      menuheader: "",
      imageLink: "",
      reservationlist: [],
    };

    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
  }

  componentDidMount() {
    /* delivery current - past orders */
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
    $("#dvLoading").fadeOut(2000);
    this.props.getReservationHistory(this.state.showresvitems);

    //Default Action
    $(".filter_tabsec").hide(); //Hide all content
    $("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab
    $(".filter_tabsec:first").show(); //Show first tab content

    //On Click Event
    $("ul.web_order_typecls li").click(function () {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });
    var order_tabs = cookie.load("orderTabs");
    if (order_tabs == "reservation") {
      cookie.save("orderTabs", "", { path: "/" });
      $(".reservation_tab").trigger("click");
    }

    this.getActivityCounts();
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadpitems();
    });
  }

  loadReservationItems() {
    showLoader("reserv-loader-div", "class");
    var pageNext = this.state.showresvitems + 10;
    this.setState({ showresvitems: pageNext }, function () {
      this.props.getReservationHistory(pageNext);
    });
  }
  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);
  }
  loadpitems() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
  }

  getActivityCounts() {
    const inputKeys = [
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
            reservation_orders:
              nextProps.activitycount[0].result_set.reservation_orders,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }

    if (nextProps.staticblack !== this.state.staticblacks) {
      var menuheader = "<p>My Account</p>";
      var imageLink = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data) => {
          if (data.staticblocks_slug === "my-account-header") {
            menuheader = data.staticblocks_description;
            var gallery_image_path = data.gallery_image_path;
            imageLink =
              data.gallery_images.length > 0
                ? gallery_image_path + data.gallery_images[0]
                : "";
            return "";
          }
        });
      }
      menuheader =
        menuheader !== "" && menuheader !== null
          ? Parser(menuheader)
          : menuheader;
      this.setState({
        menuheader: menuheader,
        imageLink: imageLink,
      });
    }


    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }

    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }
    if (nextProps.reservationlist !== this.state.reservationlist) {
      var displyTxt = "none";
      if (
        this.state.showresvitems < nextProps.reservationcommon.total_records
      ) {
        displyTxt = "inline-block";
      }
      hideLoader("reserv-loader-div", "class");
      this.setState({
        reservationlist: nextProps.reservationlist,
        resviewmore: displyTxt,
      });
    }
  }

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var params =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }
  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  getOrderItemData = (dataProp) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        const orderTime = Moment(item.order_date).format("h:mm A");

        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER # - {item.order_local_no}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              {item.order_availability_id !== dineinId && (
                <div className="order_no_deatails">
                  Order placed at :{" "}
                  {Moment(item.order_created_on).format("DD/MM/YYYY") +
                    " " +
                    Moment(item.order_created_on).format("h:mm A")}
                  <span>Pay by : {item.order_method_name}</span>
                </div>
              )}

              {item.order_availability_id === deliveryId && (
                <div className="delivery_total delivery_total_text">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>Order Handling By</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== null
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>Delivery Location</h3>
                    <span>
                      {addressFormat(
                        item.order_customer_unit_no1,
                        item.order_customer_unit_no2,
                        item.order_customer_address_line1,
                        item.order_customer_address_line2,
                        item.order_customer_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}

              {item.order_availability_id !== deliveryId &&
                item.order_availability_id !== dineinId && (
                  <div className="delivery_total delivery_total_text pickup-order-div">
                    <div className="delivery_total_left">
                      <h3>Pickup Location</h3>
                      <span>
                        {item.outlet_name !== "" &&
                        item.outlet_name !== undefined
                          ? stripslashes(item.outlet_name)
                          : ""}
                      </span>
                      <span>
                        {addressFormat(
                          item.outlet_unit_number1,
                          item.outlet_unit_number2,
                          item.outlet_address_line1,
                          item.outlet_address_line2,
                          item.outlet_postal_code
                        )}
                      </span>
                    </div>
                  </div>
                )}

              {item.order_availability_id === dineinId && (
                <div className="delivery_total delivery_total_text pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>Dine In Location</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== undefined
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}

              <div className="delivery_total delivery_total_number">
                {item.order_availability_id !== dineinId && (
                  <div className="delivery_total_left">
                    <h2>
                      {item.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      Date
                    </h2>
                    <h4 className="checkoutDate">{orderDate}</h4>
                  </div>
                )}
                {item.order_availability_id === dineinId && (
                  <div className="delivery_total_left">
                    <h2>Dine In Date</h2>
                    <h4 className="checkoutDate">
                      {orderDate} {orderTime}
                    </h4>
                  </div>
                )}

                {item.order_availability_id === dineinId && (
                  <div className="delivery_total_right">
                    <h2>Table Number</h2>
                    <h4 className="checkoutDate">{item.order_table_number}</h4>
                  </div>
                )}

                {item.order_availability_id !== dineinId && (
                  <div className="delivery_total_left delivery_total_right">
                    <h2>
                      {item.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      time
                    </h2>
                    <h4 className="checkoutTime">{orderTime}</h4>
                  </div>
                )}
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>Order Amount</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    <sup>$</sup>
                    {item.order_total_amount}
                  </h3>
                </div>
              </div>

              {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}

              {item.order_availability_id === pickupId &&
              item.order_payment_mode === "1" &&
              item.order_method_name.toLowerCase() === "cash" ? (
                <div className="order_btns">
                  <a
                    href="javascript:void(0)"
                    onClick={this.getReceipt.bind(this, item.order_id)}
                    className="button view_recipt button_full_view"
                    title=""
                  >
                    View Receipt
                  </a>
                </div>
              ) : (
                <div className="order_btns">
                  <a
                    href="javascript:void(0)"
                    onClick={this.printReceipt.bind(
                      this,
                      item.order_primary_id
                    )}
                    className="button print_invoice hidden"
                    title=""
                  >
                    Print Invoice
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={this.getReceipt.bind(this, item.order_id)}
                    className="button view_recipt button_full_view"
                    title=""
                  >
                    View Receipt
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  reservStatus(statusTxt) {
    var returnTxt = "Pending";
    statusTxt = statusTxt !== "" ? parseInt(statusTxt) : 0;
    if (statusTxt === 2) {
      returnTxt = "Cancel";
    } else if (statusTxt === 1) {
      returnTxt = "Assigned";
    } else if (statusTxt === 3) {
      returnTxt = "Arrived";
    } else if (statusTxt === 4) {
      returnTxt = "Not Arrived";
    } else if (statusTxt === 5) {
      returnTxt = "Completed";
    }

    return returnTxt;
  }

  showNoOfPax(adultCount, childCount) {
    var adultTxt =
      parseInt(adultCount) == 0
        ? ""
        : parseInt(adultCount) == 1
        ? adultCount + " Adult"
        : adultCount + " Adults";
    var childTxt =
      parseInt(childCount) == 0
        ? ""
        : parseInt(childCount) == 1
        ? childCount + " Child"
        : childCount + " Children";
    var paxText = adultTxt + " " + childTxt;

    return paxText;
  }

  sateValChange = (field, value) => {
    if (field === "homeorderInfo" && value !== "") {
      this.setState({ homeorderInfo: value });
    }
    if (field === "homesubscribe" && value !== "") {
      this.setState({ homesubscribe: value });
    }
  };

  /* reservation order - start*/
  getReservationItemData = (dataProp) => {
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.reservation_date).format("DD/MM/YYYY");
        var dataTmtxt =
          Moment(item.reservation_date).format("YYYY/MM/DD") +
          " " +
          item.reservation_start_time;
        const orderTime = Moment(dataTmtxt).format("h:mm A");

        return (
          <div
            key={item.reservation_id}
            className="current_order reserv-orderlist"
          >
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>RESERVATION # - {item.reservation_local_order_id}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{this.reservStatus(item.reservation_status)}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="order_no_deatails">
                <h2>Order placed at </h2> <h4>{Moment(item.reservation_created_on).format("DD/MM/YYYY h:mm A")}</h4>
              </div>
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>Reservation Date & Time</h2>
                  <h4 className="checkoutDate">
                    {orderDate} {orderTime}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>NO OF PAX</h2>
                  <h4 className="checkoutTime">
                    {this.showNoOfPax(
                      item.reservation_no_of_adult_pax,
                      item.reservation_no_of_children_pax
                    )}
                  </h4>
                </div>
              </div>
              <div className="order_no_deatails">
                <h3>Outlet Name</h3>
                <p>{item.reservation_outlet_name}</p>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };
  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    /*  setTimeout(function () {
    //  console.log('render1',$('#dvLoading').length);    
            $('#dvLoading').remove();
            
          }, 500);*/

    return (
      <div className="myacc-main-div">
        <Header sateValChange={this.sateValChange} />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
              <img
                src={
                  this.state.imageLink !== ""
                    ? this.state.imageLink
                    : innerbanner
                }
                alt=""
              />
                {this.state.menuheader}
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menuout">
                <ul className="mainacc_menulist">
                  <li>
                    <Link to="/myaccount" title="My Account">
                      <span>Account Details</span>
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/myorders" title="My Orders">
                      <span>Orders</span>
                      {parseFloat(this.state.overall_orders) > 0 && (
                        <span id="masterCount">
                          {this.state.overall_orders}
                        </span>
                      )}
                    </Link>
                  </li>
                  {/*<li className="">
                    <Link to="/rewards" title="My Rewards">
                      <span>Rewards</span>
                    </Link>
                  </li>*/}
                  <li>
                    <Link to="/mypromotions" title="My Promotions">
                      <span>Promotions</span>
                    </Link>
                  </li>
                  {/*<li className="">
                    <Link to="/myvouchers" title="My Vouchers">
                      <span>Vouchers</span>
                    </Link>
                  </li>*/}
                </ul>
                <div className="mbacc_mslidersec mbacc_mslider">
                  <Slider {...settingsMyAcc}>
                    <div className="mbacc_mslide">
                      <Link to="/myaccount" title="My Account">
                        <span>Account Details</span>
                      </Link>
                    </div>
                    <div className="mbacc_mslide active">
                      <Link to="/myorders" title="My Orders">
                        <span>Orders</span>
                      </Link>
                    </div>
                    {/*<div className="mbacc_mslide">
                      <Link to="/rewards" title="My Rewards">
                        <span>Rewards</span>
                      </Link>
                    </div>*/}
                    <div className="mbacc_mslide">
                      <Link to="/mypromotions" title="My Promotions">
                        <span>Promotions</span>
                      </Link>
                    </div>
                    {/*<div className="mbacc_mslide">
                      <Link to="/myvouchers" title="My Vouchers">
                        <span>Vouchers</span>
                      </Link>
          </div>*/}
                  </Slider>
                </div>
              </div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec" id="ordertab1">
                            <ul className="nav nav-tabs text-center">
                              <li className="active">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn5"
                                  aria-expanded="true"
                                >
                                  <span>Current Orders</span>
                                </a>
                              </li>
                              <li className="">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn6"
                                  aria-expanded="false"
                                >
                                  <span>Past Orders</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  class="reservation_tab"
                                  data-toggle="tab"
                                  href="#tab-id-inn9"
                                  aria-expanded="false"
                                >
                                  <span>Reservation</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                id="tab-id-inn5"
                                className="tab-pane fade active in"
                              >
                               {/* <h4 className="tab_mobtrigger inner_tab_border active">
                                  Current orders<i></i>
                                </h4>  */}
                                <div className="tab_mobrow filter_tabin">
                                  <div className="order-delivery">
                                    <div className="ord-body">
                                      <div className="cur-order-body">
                                        <div className="myacc_order_details">
                                          {this.getOrderItemData(
                                            this.state.corderdetail
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="load_more_div">
                                  <button
                                    className="load_more_data"
                                    onClick={this.loadcurrentItems.bind(this)}
                                    style={{ display: "none" }}
                                  >
                                    Load More
                                  </button>
                                </div>
                              </div>
                              <div id="tab-id-inn6" className="tab-pane fade">
                                {/*<h4 className="tab_mobtrigger inner_tab_border">
                                  Past Orders<i></i>
                                </h4> */}
                                <div className="tab_mobrow filter_tabin">
                                  <div className="pst-order-body">
                                    <div className="myacc_order_details">
                                      {this.getOrderItemData(
                                        this.state.porderdetail
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="load_more_div">
                                  <button
                                    className="load_more_data1"
                                    style={{ display: "none" }}
                                    onClick={this.loadpastItems.bind(this)}
                                  >
                                    Load More
                                  </button>
                                </div>
                              </div>

                              <div id="tab-id-inn9" className="tab-pane fade">
                                <div className="tab_mobrow filter_tabin">
                                  <div className="order-delivery">
                                    <div className="ord-body">
                                      <div className="cur-order-body">
                                        <div className="myacc_order_details">
                                          {this.getReservationItemData(
                                            this.state.reservationlist
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="load_more_div">
                                  <div className="reserv-loader-div">
                                    <button
                                      className="load_more_resdata"
                                      style={{
                                        display: this.state.resviewmore,
                                      }}
                                      onClick={this.loadReservationItems.bind(
                                        this
                                      )}
                                    >
                                      Load More
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* next tab */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.ReceiptDetails}
              cartItems={this.state.CartItems}
            />
            {this.state.ReceiptDetails &&
              <>
            {this.state.ReceiptDetails && this.state.ReceiptDetails.order_availability_id === pickupId &&
              this.state.ReceiptDetails.order_payment_mode === "1" &&
              this.state.ReceiptDetails.order_method_name.toLowerCase() === "cash" ? (<></>):(<div className="">
            <a
              href="javascript:void(0)"
              onClick={this.printReceipt.bind(
                this,
                this.state.ReceiptDetails.order_primary_id
              )}
              className="button print_invoice"
              title=""
            >
              Print Receipt
            </a>
            </div>) 
            } 
          </>
        }
          </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var reshistoryArr = Array();
  var rescommonArr = Array();
  if (Object.keys(state.reservationhistory).length > 0) {
    if (state.reservationhistory[0].status === "ok") {
      reshistoryArr = state.reservationhistory[0].result_set;
      rescommonArr = state.reservationhistory[0].common;
    }
  }
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  

  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
    activitycount: state.activitycount,
    reservationlist: reshistoryArr,
    reservationcommon: rescommonArr,
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getReservationHistory: (limitTxt) => {
      dispatch({ type: GET_RESERVATIONHISTORY, limitTxt });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },

  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row oreder_itm_row" key={index}>
          <div className="col-sm-cls cart_left">
            <div className="cart_info">
              <h4>
                {stripslashes(item.item_name)} X {item.item_qty}
              </h4>
              {this.loadModifierItems(
                "Component",
                item.modifiers,
                item.set_menu_component
              )}
            </div>
            {item.item_specification !== "" && (
              <p className="help-block">
                {stripslashes(item.item_specification)}
              </p>
            )}
            {/*} 
         <p className ="help-block">Special instruction for individual product items ...</p>
         {*/}
          </div>
          <div className="col-sm-cls cart_right text-right">
            <div className="cart_price">
              <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+$" + comboPrice + ")" : "";
        if (parseInt(comboQty) > 0) {
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p>";
        }
      }
      return html;
    }
    return "";
  }
  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+$" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p>";
      }
      html += "</div>";
    }
    return html;
  }
  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
var remarks = details.order_remarks !== ''?<div className="remark_notesec text-left"><h4>Remarks</h4><p>{details.order_remarks}</p></div>:''; 
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <div className="tnk-order">
                  <h3>Order No - {details.order_local_no}</h3>
                  {details.order_availability_id !== dineinId && (
                    <p>
                      Order placed at :{" "}
                      {Moment(details.order_created_on).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                      <br></br>Pay by : {details.order_method_name}
                    </p>
                  )}
                </div>
                <h2>YOUR ORDER DETAILS</h2>
              </div>
            </div>
            <div className="tnk-delivery">
              {details.order_availability_id === deliveryId && (
                <div className="delivery_total">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>Delivery From</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>Deliver TO</h3>
                    <span>
                      {addressFormat(
                        details.order_customer_unit_no1,
                        details.order_customer_unit_no2,
                        details.order_customer_address_line1,
                        details.order_customer_address_line2,
                        details.order_customer_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              {details.order_availability_id !== deliveryId &&
                details.order_availability_id !== dineinId && (
                  <div className="delivery_total pickup-order-div">
                    <div className="delivery_total_left">
                      <h3>Pickup Location</h3>
                      <span>
                        {details.outlet_name !== "" &&
                        details.outlet_name !== undefined
                          ? stripslashes(details.outlet_name)
                          : ""}
                      </span>
                      <span>
                        {addressFormat(
                          details.outlet_unit_number1,
                          details.outlet_unit_number2,
                          details.outlet_address_line1,
                          details.outlet_address_line2,
                          details.outlet_postal_code
                        )}
                      </span>
                    </div>
                  </div>
                )}

              {details.order_availability_id === dineinId && (
                <div className="delivery_total pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>Dine In Location</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}

              <div className="delivery_total delivery_total_number">
                {details.order_availability_id !== dineinId && (
                  <div className="delivery_total_left">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      Date
                    </h2>
                    <h4 className="checkoutDate">
                      {Moment(details.order_date).format("DD-MM-YYYY")}
                    </h4>
                  </div>
                )}
                {details.order_availability_id === dineinId && (
                  <div className="delivery_total delivery_total_number">
                    <div className="delivery_total_left">
                      <h2>Dine In Date</h2>
                      <h4 className="checkoutDate">
                        {Moment(details.order_date).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                      </h4>
                    </div>
                    <div className="delivery_total_right">
                      <h2>Table Number</h2>
                      <h4 className="checkoutDate">
                        {details.order_table_number}
                      </h4>
                    </div>
                  </div>
                )}

                {details.order_availability_id !== dineinId && (
                  <div className="delivery_total_left delivery_total_right">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      time
                    </h2>
                    {/* Advanced Slot */}
                    <h4 className="checkoutTime">
                      {this.showOrdTimeSlot(details)}
                    </h4>
                  </div>
                )}
              </div>

              <div className="order-items-maindiv">
                {this.getCartDetList(cartItems)}
              </div>
              {remarks}

              <div className="cart_footer tnk_cart_footer">
                <div className="cart_row">
                  <p className="text-uppercase">SUBTOTAL</p>
                  <span>${details.order_sub_total}</span>
                </div>
                {parseFloat(details.order_discount_amount) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      {details.order_discount_type === "redeem"
                        ? "Discount (-)"
                        : "Promocode (-)"}
                    </p>
                    <span>${parseFloat(details.order_discount_amount).toFixed(2)}</span>
                  </div>
                )}
                {parseFloat(details.order_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">Delivery Charges</p>
                    <span>
                      $
                      {(
                        parseFloat(details.order_delivery_charge) +
                        parseFloat(details.order_additional_delivery)
                      ).toFixed(2)}
                    </span>
                  </div>
                )}
                {parseFloat(details.order_service_charge_amount) > 0 && (
                  <div className="cart_row">
                    {parseFloat(details.order_service_charge) > 0 ? (
                      <p className="text-uppercase">
                        Service Charge (
                        {parseFloat(details.order_service_charge)}%)
                      </p>
                    ) : (
                      <p className="text-uppercase">Service Charge</p>
                    )}
                    <span>
                      $
                      {parseFloat(details.order_service_charge_amount).toFixed(
                        2
                      )}
                    </span>
                  </div>
                )}

                {parseFloat(details.order_subcharge_amount) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">Surcharge</p>
                    <span>
                      {parseFloat(details.order_subcharge_amount).toFixed(2)}
                    </span>
                  </div>
                )}
                {details.order_tax_calculate_amount > 0 && (
                  <div className="cart_row gst-row">
                    <p className="text-uppercase">
                      GST({details.order_tax_charge}%)
                    </p>
                    <span>${details.order_tax_calculate_amount}</span>
                  </div>
                )}
                {(details.order_round_off) != '' && (details.order_round_off) != null && (
                  <div className="cart_row gst-row">
                    <p className="text-uppercase">
                      Rounding Off
                    </p>
                    <span>{details.order_round_off}</span>
                  </div>
                )}
                <div className="cart_row cart_footer_totrow grant-total-cls">
                  <p className="text-uppercase">Total</p>
                  <span>${details.order_total_amount}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
