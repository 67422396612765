/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";
import axios from "axios";
import { appId, apiUrl } from "../Helpers/Config";
import { stripslashes, showAlert } from "../Helpers/SettingHelper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import innerbanner from "../../common/images/inner-banner.jpg";
import {
  GET_STATIC_BLOCK,
} from "../../actions";

var qs = require("qs");

class NewsPromos extends Component {
  constructor(props) {
    super(props);
        this.state = {
      newsPromoSection: "",
      newsLetterEmail: "",
      staticblacks: [],
      menuheader: "",
      imageLink: "",
    };
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.staticblack !== this.state.staticblacks) {
      var menuheader = "<p>News & Promo</p>";
      var imageLink = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data) => {
          if (data.staticblocks_slug === "news-promo-header") {
            menuheader = data.staticblocks_description;
            var gallery_image_path = data.gallery_image_path;
            imageLink =
              data.gallery_images.length > 0
                ? gallery_image_path + data.gallery_images[0]
                : "";
            return "";
          }
        });
      }
      menuheader =
        menuheader !== "" && menuheader !== null
          ? Parser(menuheader)
          : menuheader;
      this.setState({
        menuheader: menuheader,
        imageLink: imageLink,
      });
    }
  }


  sateValChange = (field, value) => {

    if (field === "newsPromoSection" && value !== "") {
      this.setState({ newsPromoSection: value });
    }
    
  };

checkEmail(){

    if (this.state.newsLetterEmail !== "") {

      var postObject = {
        app_id: appId,
        email: this.state.newsLetterEmail,
      };

      axios
        .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            var magnfPopup = $.magnificPopup.instance;
            showAlert("Success", "Newsletter Subscribed", magnfPopup);
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          }else{
            showAlert("Error", "Enter a valid email", magnfPopup);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });

    }

}


handleChangeemail(event){
  this.setState({newsLetterEmail : event.target.value});
}

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header  sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
              <img
                src={
                  this.state.imageLink !== ""
                    ? this.state.imageLink
                    : innerbanner
                }
                alt=""
              />
                {this.state.menuheader}
          </div>
        </div>

        <div className="cms-page">
          <div className="container-one cms-content">
            <div className="container cms-content">        
              <section className="subs-news">
                <div className="container">
                  {this.state.newsPromoSection}
                  <div className="news-form-group">
                    <input
                      type="email" onChange={this.handleChangeemail.bind(this)} 
                      placeholder="Insert your email"
                      className="form-control"
                      value={this.state.newsLetterEmail}
                    />
                    <button className="news-btn button news-letter-subscribe" onClick={this.checkEmail.bind(this)}>Sign Up</button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsPromos));
