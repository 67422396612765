/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import Slider from "react-slick";
import catImg from "../../common/images/menu-cat-background.jpg";

class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
    };
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    let navigateCommon = this.props.navigateCommon;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <div className="menu-cat-over">
                <Link
                  to={"/products/category/" + loaddata.pro_cate_slug}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
                <img src={navigateCommon.category_image_url +"/"+ loaddata.pro_cate_image}
                  alt={loaddata.category_name}
                />                
              </div>
            </div>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        }
      });

      return mainMenu;
    } else {
      return;
    }
  }

  navigateMobileMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var selectedNav = this.props.productState.selectedNavigation;
    let navigateCommon = this.props.navigateCommon;
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type == "main") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/products/category/" + loaddata.pro_cate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                <p>{stripslashes(loaddata.menu_custom_title.toLowerCase())}</p>
                <img src={navigateCommon.category_image_url +"/"+ loaddata.pro_cate_image}
                  alt={loaddata.category_name}
                />
              </Link>
            </div>
          );
        } else if (loaddata.menu_type == "sub") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
            >
              <Link
                to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        }
      });

      return mainMenu;
    } else {
      return;
    }
  }
  render() {
    var settingsGallery = {
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    var settingProduct = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      margin: 10,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <>
        <div className="menu-section-left">
          <div className="menu-section-left-inner">
            <Slider {...settingProduct}>{this.navigateMenuList()}</Slider>
          </div>
        </div>
        <div className="menu-section-mobile-inner">
          <Slider {...settingsGallery}>{this.navigateMobileMenuList()}</Slider>
        </div>
      </>
    );
  }
}

export default MenuNavigation;
