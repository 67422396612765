/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { appId, apiUrl, timThumpUrl  } from "../../Helpers/Config";
import { stripslashes, callImage  } from "../../Helpers/SettingHelper";
import Slider from "react-slick";

class OurOutlets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whatshappening: [],
      whatshappeningcommon: "",
    };
    this.loadwhatshappening();
  }
  loadwhatshappening() {
    axios.get(apiUrl + "cms/whats_happening?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        this.setState({
          whatshappening: res.data.result_set,
          whatshappeningcommon: res.data.common,
        });
      } else {
      }
    });
  }
  render() {
    
  var topIndxCunt = (Object.keys(this.state.whatshappening).length > 3) ? 3 : Object.keys(this.state.whatshappening).length; 
  var innrIndxCunt = (Object.keys(this.state.whatshappening).length > 2) ? 2 : Object.keys(this.state.whatshappening).length; 
    
      
     let Promotions = {
      dots: true,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          },
        },
         {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        ]
      };
    
    
    return (
      <>
        {this.state.whatshappening.length > 0 &&
          this.state.whatshappening.map((loaddata, index) => {
            return (
              <div className="container-two" key={'wh'+(index+1)}>
              <div className="our-promotions-section">
                <div className="container">
                  <h2>{loaddata.wh_name}</h2>

                  <div className="products-list-ulmain">
                    <Slider {...Promotions}>
                    
                      {loaddata.gallery_image.map((loaddatagall, gallindex) => {
                        
                      return(<div key={'whgall'+index+''+(gallindex+1)} onClick={() => this.setState({photoIndex:this.state.whatshappeningcommon.image_source+"/"+loaddatagall.gallery_image, isOpen: true })}>
                          
                          
                        {callImage(this.state.whatshappeningcommon.image_source, loaddatagall.gallery_image, 315, 447, timThumpUrl)}
                        
                        </div>);
                      
                    })}

                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            );
          })}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(OurOutlets);
